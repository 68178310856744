.navbar-container {
  background-color: #5dacbd;
}

/* Media query for viewport width > 576px */
@media (min-width: 576px) {
  .navbar-container {
    padding-left: 40px;
    padding-right: 100px;
    padding-top: 30px;
    padding-bottom: 20px;
  }
}


.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #708914;
  margin-bottom: 20px;
  height: 70vh;
}

.inner-container {
  background-color: #1A202C;
  padding: 30px 25px 15px 25px;
  border-radius: 8px;
  max-width: 1200px;
  width: 100%;
  overflow: auto;
  height: 100%;
}

.chat-container {
  display: flex;
  flex-direction: column;
  height: 60%;
  max-height: 60%;
  overflow: auto;
  background-color: #2D3748;
  padding: 20px;
  border-radius: 8px;
  margin-bottom: 20px;
  margin-left: auto;
}

@media (max-width: 768px) {
  .main-container {
    height: 90vh;
  }

  .chat-container {
    height: 80%;
    max-height: 80%;
  }
}